import actionKeys, {
  CLEAR_COMPANIES,
  CLEAR_COMPANY,
  CLEAR_COMPANY_CREATED,
  CLEAR_CREATED_COMPANY_MANAGER_USER_ID,
} from 'omniplatform/admin/store/actions/reduxConstants';
import { Reducer } from 'redux';

const initialState = {
  companies: [],
  companyManagers: [],
  companyManager: null,
  selectedCompany: { id: null },
  companyCreated: false,
  newCompanyId: null,
  createdCompanyManagerUserId: null,
  companyEmployeesOnboardingProgress: {},
};

export const companiesReducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionKeys.GET_COMPANIES_END:
      return {
        ...state,
        companies: action.payload,
      };
    case CLEAR_COMPANIES:
      return {
        ...state,
        companies: null,
      };
    case actionKeys.GET_COMPANY_END:
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case CLEAR_COMPANY:
      return {
        ...state,
        selectedCompany: null,
      };
    case actionKeys.CREATE_COMPANY_END:
      return {
        ...state,
        companyCreated: true,
        createdCompanyManagerUserId: action.payload.createdCompanyManagerUserId,
        newCompanyId: action.payload.newCompanyId,
      };
    case actionKeys.CREATE_COMPANY_MANAGER_END:
      return {
        ...state,
        createdCompanyManagerUserId: action.payload.companyManagerUserId,
      };
    case actionKeys.UPDATE_COMPANY_END:
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case CLEAR_COMPANY_CREATED:
      return {
        ...state,
        companyCreated: false,
      };
    case CLEAR_CREATED_COMPANY_MANAGER_USER_ID:
      return {
        ...state,
        createdCompanyManagerUserId: null,
      };
    case actionKeys.GET_COMPANY_MANAGERS_END:
      return {
        ...state,
        companyManagers: action.payload,
      };
    case actionKeys.GET_COMPANY_MANAGER_END:
      return {
        ...state,
        companyManager: action.payload,
      };
    default:
      return state;
  }
};
