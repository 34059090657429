import appHistory from 'app/appHistory';
import { SET_ERROR_MESSAGE } from 'app/store/app.constants';
import { getUserRoleSelector } from 'app/store/app.selectors';
import { transformUser } from 'app/store/app.transformers';
import { DuplicateEmailErrorMessage } from 'common/components/molecules/ErrorBanner/ErrorMessages';
import { HTTPStatusCodesEnum } from 'common/constants';
import { transformCompanyManagerToApi } from 'common/store/transformers/company.transformer';
import adminActionKeys from 'omniplatform/admin/store/actions/reduxConstants';
import { COMPANIES_LIST_PAGE } from 'paths';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createActionObject } from 'utils/redux-utils';
import { sortAlphabetically } from 'utils/sort.util';

import {
  createCompanyManagerRequest,
  createCompanyRequest,
  getCompaniesRequest,
  getCompanyManagerRequest,
  getCompanyManagersRequest,
  getCompanyRequest,
  updateCompanyRequest,
  updateManagerRequest,
} from '../../api/companies.api';
import {
  transformCompanyForTable,
  transformCompanyForUpdate,
  transformCompanyFromApi,
  transformCompanyManager,
  transformCompanyToAPI,
} from '../transformers/company.transformer';

function* getCompanies() {
  try {
    const { data: companies } = yield getCompaniesRequest();
    yield put(
      createActionObject(
        adminActionKeys.GET_COMPANIES_END,
        sortAlphabetically(
          'companyName',
          companies.map(transformCompanyForTable),
        ),
      ),
    );
  } catch (e) {
    yield put(createActionObject(adminActionKeys.GET_COMPANIES_ERROR, e));
  }
}

function* getCompany({ payload: companyId }) {
  try {
    const { data: company } = yield getCompanyRequest(companyId);
    yield put(
      createActionObject(
        adminActionKeys.GET_COMPANY_END,
        transformCompanyFromApi(company),
      ),
    );
  } catch (e) {
    yield put(createActionObject(adminActionKeys.GET_COMPANY_ERROR, e));
  }
}

function* createCompany({ payload }) {
  try {
    const {
      data: { id: newCompanyId, createdManagers },
    } = yield createCompanyRequest(transformCompanyToAPI(payload));

    const createdCompanyManagerUserId = createdManagers[0];

    yield put(
      createActionObject(adminActionKeys.CREATE_COMPANY_END, {
        newCompanyId,
        createdCompanyManagerUserId,
      }),
    );
  } catch (e) {
    if (e.response.status === HTTPStatusCodesEnum.CONFLICT) {
      yield put(
        createActionObject(SET_ERROR_MESSAGE, DuplicateEmailErrorMessage),
      );
    }
    yield put(createActionObject(adminActionKeys.CREATE_COMPANY_ERROR, e));
  }
}

function* updateCompany({
  payload: { companyId, editedFields, redirectURL, useAppHistory = true },
}) {
  try {
    const userRole = yield select(getUserRoleSelector);
    const returnURL =
      userRole === 'admin' ? `${COMPANIES_LIST_PAGE}/${companyId}` : '/profile';
    const { data: company } = yield updateCompanyRequest(
      transformCompanyForUpdate(editedFields),
      companyId,
    );
    yield put(
      createActionObject(adminActionKeys.CREATE_COMPANY_END, companyId),
    );
    yield put(
      createActionObject(
        adminActionKeys.UPDATE_COMPANY_END,
        transformCompanyFromApi(company),
      ),
    );
    if (useAppHistory) {
      appHistory.push(redirectURL || returnURL);
    } else {
      redirectURL && (window.location.href = redirectURL);
    }
  } catch (e) {
    yield put(createActionObject(adminActionKeys.UPDATE_COMPANY_ERROR, e));
  }
}

function* getCompanyManagers({ payload: companyId }) {
  try {
    const response = yield call(getCompanyManagersRequest, companyId);
    const { data: managers } = response;
    yield put(
      createActionObject(
        adminActionKeys.GET_COMPANY_MANAGERS_END,
        managers.map(transformCompanyManager),
      ),
    );
  } catch (e) {
    yield put(
      createActionObject(adminActionKeys.GET_COMPANY_MANAGERS_ERROR, e),
    );
  }
}

function* getCompanyManager({ payload }) {
  try {
    const { data: manager } = yield call(
      getCompanyManagerRequest,
      payload.managerId,
    );
    yield put(
      createActionObject(
        adminActionKeys.GET_COMPANY_MANAGER_END,
        transformUser(manager),
      ),
    );
  } catch (e) {
    yield put(createActionObject(adminActionKeys.GET_COMPANY_MANAGER_ERROR, e));
  }
}

function* updateCompanyManager({
  payload: { managerId, companyId, updatedDetails },
}) {
  try {
    yield call(updateManagerRequest, companyId, managerId, updatedDetails);
    yield put(createActionObject(adminActionKeys.UPDATE_COMPANY_MANAGER_END));
    appHistory.push(
      `${COMPANIES_LIST_PAGE}/${companyId}/managers/${managerId}`,
    );
  } catch (e) {
    yield put(
      createActionObject(adminActionKeys.UPDATE_COMPANY_MANAGER_ERROR, e),
    );
  }
}

function* createCompanyManager({ payload: { companyId, managerDetails } }) {
  try {
    const {
      data: { id: companyManagerUserId },
    } = yield call(
      createCompanyManagerRequest,
      companyId,
      transformCompanyManagerToApi(managerDetails),
    );

    yield put(
      createActionObject(adminActionKeys.CREATE_COMPANY_MANAGER_END, {
        companyManagerUserId,
      }),
    );
  } catch (e) {
    if (e.response.status === HTTPStatusCodesEnum.CONFLICT) {
      yield put(
        createActionObject(SET_ERROR_MESSAGE, DuplicateEmailErrorMessage),
      );
    }
    yield put(
      createActionObject(adminActionKeys.CREATE_COMPANY_MANAGER_ERROR, e),
    );
  }
}

export default [
  takeLatest(adminActionKeys.GET_COMPANIES_START, getCompanies),
  takeLatest(adminActionKeys.GET_COMPANY_START, getCompany),
  takeLatest(adminActionKeys.CREATE_COMPANY_START, createCompany),
  takeLatest(adminActionKeys.UPDATE_COMPANY_START, updateCompany),
  takeLatest(adminActionKeys.GET_COMPANY_MANAGERS_START, getCompanyManagers),
  takeLatest(adminActionKeys.GET_COMPANY_MANAGER_START, getCompanyManager),
  takeLatest(
    adminActionKeys.UPDATE_COMPANY_MANAGER_START,
    updateCompanyManager,
  ),
  takeLatest(
    adminActionKeys.CREATE_COMPANY_MANAGER_START,
    createCompanyManager,
  ),
];
