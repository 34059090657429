import { AxiosError } from 'axios';
import { createTaxonomiesUpdateJob } from 'common/api/payrollTaxonomy.api';
import {
  CreateTaxonomiesUpdateJobPayload,
  TaxonomiesUpdateJob,
} from 'common/types';
import { useMutation } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useTaxonomiesUpdateJobMutation = () => {
  return useMutation<
    TaxonomiesUpdateJob,
    AxiosError,
    CreateTaxonomiesUpdateJobPayload
  >((data) => createTaxonomiesUpdateJob(data).then(getDataFromResponse));
};
