import {
  InvoicesLogsQueryOptions,
  ListCutoffReportsParams,
  PayrollReportDataCurrency,
} from 'common/types';
import { createQueryKeys } from 'utils/react-query-utils';

export const payrollKeys = createQueryKeys('payroll', (createKey) => ({
  single: (payrollInstanceId: string) => createKey(payrollInstanceId),
  invoices: (invoiceIds?: string[]) =>
    createKey('default', 'invoices', invoiceIds),
  billingItems: () => createKey('default', 'billing-items'),
  billingItemsPaginated: () => createKey('default', 'billing-items-paginated'),
  importedBenefitsBillingsStats: (payrollInstanceId: string) =>
    createKey(payrollInstanceId, 'imported-benefits-billings-stats'),
  importedBenefitsBillingsByClient: (
    payrollInstanceId: string,
    clientId: string,
  ) =>
    createKey(payrollInstanceId, clientId, 'imported-benefits-billings-stats'),
  employeeBillingItems: (employeeId: string) =>
    createKey(employeeId, 'employee-billing-items'),
  lspBillingItems: (status = 'not-exported') =>
    createKey(status, 'lsp-billing-items'),
  payrollInstanceInvoices: (payrollInstanceId: string) =>
    createKey(payrollInstanceId, 'payroll-instance-id'),
  lspProcessedBillingItems: (status = 'payment:processed') =>
    createKey(status, 'lsp-processed-payments'),
  clientBillingItems: (payrollInstanceId: string, status = 'not-exported') =>
    createKey(payrollInstanceId, status, 'client-billing-items'),
  clientInvoices: (payrollInstanceId: string) =>
    createKey(payrollInstanceId, 'client-invoices'),
  clientExportView: () => createKey('default', 'client-export-view'),
  fxRate: (fromCurrency: string, toCurrency: string) =>
    createKey(fromCurrency, toCurrency, 'fx-rate'),
  fxRates: (fromCurrency?: string, toCurrency?: string) =>
    createKey(fromCurrency, toCurrency, 'fx-rates'),
  parseInvoiceFromSpreadsheet: () =>
    createKey('default', 'parse-invoice-from-spreadsheet'),
  parseInvoiceFromWorkbook: () =>
    createKey('default', 'parse-invoice-from-workbook'),
  parseBenefitsFromSpreadsheet: () =>
    createKey('default', 'parse-benefits-from-spreadsheet'),
  payrollInstances: () => createKey('default', 'payroll-instances'),
  payrollInstancesPaginated: () =>
    createKey('default', 'payroll-instances-paginated'),
  invoiceLogs: (queryOptions: InvoicesLogsQueryOptions = {}) =>
    createKey('invoice-logs', queryOptions),
  netsuiteCustomers: () => createKey('default', 'netsuite-customers'),
  grossToNetFiles: (params: Record<string, unknown>) =>
    createKey(...Object.values(params), 'gross-to-net-files'),
  grossToNet: (gtnId: string) => createKey(gtnId, 'gross-to-net'),
  dataProcessingErrors: (dataFrameIdsList: string[]) =>
    createKey(dataFrameIdsList.join('-'), 'data-processing-errors'),
  payReportPreview: (queryOptions: {
    dataFrameId: string;
    pageIndex?: number;
    pageSize?: number;
    variancePayrollPeriod?: string;
    variancePayrollCycle?: string;
  }) => createKey('pay-report-preview', queryOptions),
  payReportPreviewBulk: (
    dataFrameIds: string[],
    dataCurrency: PayrollReportDataCurrency,
    variancePayrollPeriod?: string,
    variancePayrollCycle?: string,
  ) =>
    createKey(
      'pay-report-preview-bulk',
      ...dataFrameIds,
      dataCurrency,
      variancePayrollPeriod,
      variancePayrollCycle,
    ),
  grossToNetManualView: (payrollPeriod: string) =>
    createKey(payrollPeriod, 'gross-to-net-manual-view'),
  grossToNetDetailView: (country: string, lspId: string) =>
    createKey(country, lspId, 'gross-to-net-detail-view'),
  payReportJustifications: (queryOptions: {
    grossToNetId: string;
    employeeId?: string;
  }) => createKey('pay-report-justifications', queryOptions),
  payReportJustificationsByGTN: () =>
    createKey('pay-report-justifications', 'ByGTNIds'),
  payReportJustificationsByGTNWithIds: (queryOptions: {
    grossToNetIds: string[];
  }) => createKey('pay-report-justifications', 'ByGTNIds', queryOptions),
  payrollReportPeriodsList: (companyId: string) =>
    createKey(companyId, 'payroll-report-periods-list'),
  cutoffReportList: (queryOptions: ListCutoffReportsParams) =>
    createKey('cutoff-report-list', queryOptions),
  cutoffReportFilterList: () =>
    createKey('default', 'cutoff-report-filter-list'),
  taxonomiesUpdateJobs: (params: Record<string, unknown>) =>
    createKey(...Object.values(params), 'taxonomies-update-jobs'),
  configuredLEIDs: () => createKey('configured-le-ids'),
}));
