import { LeaveType, LeaveTypeReason } from 'common/types';

import type { TagIntent } from '@omnipresentgroup/design-system';

type LeaveTypeContent = {
  label: Capitalize<LeaveTypeReason>;
  intent: TagIntent;
};

export const LEAVE_TYPES_CONTENT: Record<LeaveType, LeaveTypeContent> = {
  'op:leave:sick': {
    label: 'Sick',
    intent: 'error',
  },
  'op:leave:other': {
    label: 'Other',
    intent: 'default',
  },
  // This is a fallback for unknown leave types, as generally they should get mapped
  // OTOH clients don't need to see the different values, other is fine
  'op:leave:unknown': {
    label: 'Other',
    intent: 'default',
  },
  'op:leave:holiday': {
    label: 'Holiday',
    intent: 'success',
  },
  'op:leave:compassionate': {
    label: 'Compassionate',
    intent: 'warning',
  },
};
