export const ACCESS_TOKEN = 'ACCESS_TOKEN';

export const ALLOWED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/rtf',
  'text/plain',
  'application/vnd.ms-excel',
];

export type AllowedFileType = (typeof ALLOWED_FILE_TYPES)[number];

export const HOLIDAY_TYPES = {
  INCLUDING_PUBLIC_HOLIDAYS:
    'holiday:yearly-allowance-including-public-holidays',
  EXCLUDING_PUBLIC_HOLIDAYS:
    'holiday:yearly-allowance-excluding-public-holidays',
  UNLIMITED: 'holiday:yearly-allowance-unlimited',
  GENERIC: 'holiday:yearly-allowance-generic',
};

export const AUTH0_ROLES = 'http://omniplatform.com/roles';
export const SUPPORT_EMAIL = 'mailto:help@omnipresent.com';

export const genderTypeOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'X', label: 'X' },
];

export const documentTypeOptions = [
  { value: 'Passport', label: 'Passport' },
  { value: 'National Identity Number', label: 'National Identity Number' },
  {
    value: 'Social insurance number',
    label: 'Social insurance number',
  },
  { value: 'Tax ID', label: 'Tax ID' },
];

export const MEGABYTE_BYTES = 1048576;

export const Departments = [
  'Design',
  'Engineering',
  'Finance',
  'Human Resources',
  'Legal',
  'Management',
  'Marketing',
  'Operations',
  'Product',
  'Sales',
  'Other',
] as const;

export type Department = (typeof Departments)[number];

export const departmentTypeOptions = Departments.map((department) => ({
  value: department,
  label: department,
}));
