import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import LinkAsButton from 'common/components/atoms/LinkAsButton/LinkAsButton';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import { DuplicateEmailErrorMessage } from 'common/components/molecules/ErrorBanner/ErrorMessages';
import FormHeader from 'common/components/molecules/FormHeader/FormHeader';
import FormSidebar from 'common/components/molecules/FormSidebar/FormSidebar';
import { StyledBackLink } from 'common/components/molecules/PageHeader/PageHeader.styles';
import StagedForm from 'common/components/organisms/StagedForm/StagedForm';
import * as StyledForm from 'common/components/organisms/StyledFormPage/StyledFormPage.styles';
import { getSelectedCompanySelector } from 'common/store/selectors/companies.selectors';
import { transformCreateEmployeePayload } from 'common/store/transformers/createEmployeePayload.transformers';
import {
  ContractTemplateApiResponse,
  PENDING_REVIEW,
  Seat,
} from 'common/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getCompanyAction } from 'omniplatform/admin/store/actions/companies.actions';
import {
  CONTRACTUAL_COMPENSATION_PATH,
  getContractPath,
  getEmployeeProfilePage,
  getEmployeeSeatListPath,
  MANAGER_SEATS_LIST_PATH,
} from 'paths';
import { useDispatch, useSelector } from 'react-redux';
import { is409ConflictError } from 'utils/error.helper';
import { useSelectUserRoles } from 'utils/hooks';
import { useTrack } from 'utils/mixpanel/tracker';
import {
  useClientEmployeeNetsuiteMutation,
  useConvertSeatToEmployeeMutation,
  useSaveSeatEmployeeProfileMutation,
} from 'utils/queries';

import { Stack, Viewport } from '@omnipresentgroup/design-system';

import { EditMode } from '../Contracts/Edit';
import { useBenefitsSelectionContext } from './Benefits/BenefitsSelectionContext';
import { transformEmployeePayLoad } from './config/generateContractDetailsStage';
import { seatToEmployeeFormConfig } from './config/seatToEmployeeFormConfig';

function getErrorMessage(error: Error) {
  return is409ConflictError(error) ? DuplicateEmailErrorMessage : error.message;
}

type Props = {
  seat: Seat;
  contractTemplate?: ContractTemplateApiResponse;
  companyId: string;
  countryCode: string;
};

const SeatToEmployeeConversionForm = ({
  seat,
  contractTemplate,
  companyId,
  countryCode,
}: Props) => {
  const entryDefinitions =
    contractTemplate &&
    contractTemplate[0] &&
    contractTemplate[0].entryDefinitions;

  const history = useHistory();
  const dispatch = useDispatch();
  const company = useSelector(getSelectedCompanySelector);

  const {
    isAdmin,
    isManager,
    main: userRole,
    isCSAdmin,
    isEmployee,
    isOPAdmin,
  } = useSelectUserRoles();
  const { enableClientBenefitSelection } = useFlags();

  const benefits = useBenefitsSelectionContext();

  const showBenefitsSelectionStep =
    !!benefits.countryData?.showBenefitsSelection;

  const employeeConfig = useMemo(
    () =>
      seatToEmployeeFormConfig({
        seat,
        entryDefinitions,
        companyName: company?.companyName,
        showBenefitsSelectionStep,
        enableClientBenefitSelection,
        userRole,
      }),
    [
      seat,
      entryDefinitions,
      company?.companyName,
      showBenefitsSelectionStep,
      enableClientBenefitSelection,
      userRole,
    ],
  );

  const { formCopy, formTitle, stages } = employeeConfig;

  const [currentStage, setCurrentStage] = useState(0);
  const [formFields, updateFormFields] = useState(stages);
  const track = useTrack();

  const [loading, setLoading] = useState(false);

  const pendingSeatsPath = isAdmin
    ? getEmployeeSeatListPath(companyId)
    : MANAGER_SEATS_LIST_PATH;

  const seatMutation = useConvertSeatToEmployeeMutation();
  const extEmployeeNSMutation = useClientEmployeeNetsuiteMutation();
  const seatEmployeeProfileMutation = useSaveSeatEmployeeProfileMutation();

  const { mutate } = seatMutation;
  const { mutate: extEmployeeMutate } = extEmployeeNSMutation;

  const createAnalyticsEvent = useCallback(
    (seat: Seat, companyId: string, contractTemplate: any) => ({
      isAutomatedContract: !!(contractTemplate && contractTemplate[0]),
      clientId: companyId,
      countryCode,
      seatId: seat.id,
    }),
    [],
  );

  useEffect(() => {
    track(
      'Show Pending Employee Profile',
      createAnalyticsEvent(seat, companyId, contractTemplate),
    );
  }, []);

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyAction(companyId));
    }
  }, [companyId, dispatch]);

  const handleFormSubmit = useCallback(
    (fields: Record<string, unknown>) => {
      const employeePayload = transformEmployeePayLoad(
        transformCreateEmployeePayload(fields),
        contractTemplate?.[0]?.id,
        entryDefinitions,
      );

      const payload = {
        employeePayload,
        isMultiDocFlow:
          entryDefinitions &&
          (entryDefinitions.some(
            (entryDefinition) =>
              entryDefinition.attribute === 'addDocsUploaded',
          ) ||
            false),
      };

      track(
        'Convert Seat To Employee Clicked',
        createAnalyticsEvent(seat, companyId, contractTemplate),
      );

      mutate(payload, {
        onSuccess: (
          { data }: any,
          { isMultiDocFlow }: Record<string, boolean>,
        ) => {
          const { id: employeeId, metadata } = data;
          const contract = metadata?.contracts?.[0];

          //create client employee ids in netsuite.
          extEmployeeMutate({ employeeId });

          // redirect to contructual compensation page for managers
          if (isManager) {
            window.location.href = `${
              process.env.VITE_MANAGER_APP
            }${CONTRACTUAL_COMPENSATION_PATH(employeeId)}?mode=edit`;
            return setLoading(true);
          }

          if (!contract) {
            return history.push(getEmployeeProfilePage(employeeId));
          }

          const isMultiRoleContract = contract.status === PENDING_REVIEW;
          const userCompletesMissingInfo = isMultiRoleContract && isCSAdmin;
          const userWaitsMissingInfoEntry =
            isMultiRoleContract && (isManager || isOPAdmin || isAdmin);

          if (userCompletesMissingInfo) {
            return history.push(
              `${getContractPath(
                isEmployee,
                contract.contractId,
                employeeId,
              )}/edit?${EditMode.complete_missing_information}`,
            );
          }
          const hideContract =
            userWaitsMissingInfoEntry ||
            (isManager && employeePayload?.rightToWorkCheckCompleted === false);

          if (contract.contractId && !hideContract) {
            return history.push(
              getContractPath(false, contract.contractId, employeeId),
            );
          }

          if (userWaitsMissingInfoEntry && isMultiDocFlow) {
            return history.push(
              `${getEmployeeProfilePage(
                employeeId,
              )}?multidoc-success-pending-review`,
            );
          }

          if (userWaitsMissingInfoEntry) {
            return history.push(
              `${getEmployeeProfilePage(employeeId)}?success-pending-review`,
            );
          }
          return history.push(getEmployeeProfilePage(employeeId));
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, mutate, entryDefinitions, contractTemplate],
  );

  const onExitClick = useCallback(
    () => history.push(pendingSeatsPath),
    [history, pendingSeatsPath],
  );

  const handleSaveAndCloseClick = useCallback(() => {
    const updatedEmployeeProfile = transformEmployeePayLoad(
      transformCreateEmployeePayload(formFields),
      contractTemplate?.[0]?.id,
      entryDefinitions,
    );

    delete updatedEmployeeProfile.country;

    track(
      'Save Pending Employee Profile Clicked',
      createAnalyticsEvent(seat, companyId, contractTemplate),
    );

    seatEmployeeProfileMutation.mutate(updatedEmployeeProfile, {
      onSuccess: () => {
        history.push(pendingSeatsPath);
      },
    });
  }, [
    companyId,
    contractTemplate,
    createAnalyticsEvent,
    entryDefinitions,
    formFields,
    history,
    pendingSeatsPath,
    seat,
    seatEmployeeProfileMutation,
    track,
  ]);

  return (
    <PageContent>
      <Viewport devices={['phone', 'tablet']}>
        <StyledBackLink data-testid="page-header-backlink">
          <LinkAsButton onClick={handleSaveAndCloseClick}>
            Save and close
          </LinkAsButton>
        </StyledBackLink>
      </Viewport>

      {seatMutation &&
        seatMutation.isError &&
        seatMutation.error instanceof Error && (
          <ErrorBanner
            errorMessage={getErrorMessage(seatMutation.error)}
            onErrorClear={seatMutation.reset}
          />
        )}

      {seatEmployeeProfileMutation &&
        seatEmployeeProfileMutation.isError &&
        seatEmployeeProfileMutation.error instanceof Error && (
          <ErrorBanner
            errorMessage={getErrorMessage(seatEmployeeProfileMutation.error)}
            onErrorClear={seatEmployeeProfileMutation.reset}
          />
        )}

      <StyledForm.StyledFormPage data-testid="add-employee-page">
        <StyledForm.StyledFormPageContent>
          <Viewport devices={['phone', 'tablet']}>
            <FormHeader
              stages={stages}
              title={formTitle}
              currentStage={currentStage}
            />
          </Viewport>
          <Viewport devices={['laptop', 'desktop', 'highRes']}>
            <Stack>
              <StyledBackLink data-testid="page-header-backlink">
                <LinkAsButton onClick={handleSaveAndCloseClick}>
                  Save and close
                </LinkAsButton>
              </StyledBackLink>
              <FormSidebar
                copy={formCopy}
                stages={stages}
                title={formTitle}
                currentStage={currentStage}
              />
            </Stack>
          </Viewport>
          <StagedForm
            key={company?.companyName}
            formConfig={employeeConfig}
            country={seat.countryName}
            onFormSubmit={handleFormSubmit}
            onStageChanged={setCurrentStage}
            submissionSuccess={seatMutation.isSuccess}
            loading={loading || seatMutation.isLoading}
            onFormChangeHandler={(updatedFields: any) =>
              updateFormFields(updatedFields)
            }
            onExitClick={onExitClick}
          />
        </StyledForm.StyledFormPageContent>
      </StyledForm.StyledFormPage>
    </PageContent>
  );
};

export default SeatToEmployeeConversionForm;
