import { getTaxonomiesUpdateJobs } from 'common/api/payrollTaxonomy.api';
import { TEN_SECONDS, THIRTY_SECONDS } from 'common/constants';
import { ListTaxonomiesUpdateJobsApiResponse } from 'common/types';
import { isNil, omitBy } from 'lodash';
import { useQuery, UseQueryOptions } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

import { payrollKeys } from '../keys';

export const usePaginatedTaxonomiesUpdateJobsQuery = (
  params: {
    pageSize?: number;
    pageIndex?: number;
  },
  queryOptions?: Pick<
    UseQueryOptions<ListTaxonomiesUpdateJobsApiResponse>,
    'staleTime' | 'cacheTime' | 'enabled' | 'refetchInterval'
  >,
) => {
  return useQuery<ListTaxonomiesUpdateJobsApiResponse>(
    payrollKeys.taxonomiesUpdateJobs({ ...omitBy(params, isNil) }),
    () => getTaxonomiesUpdateJobs(params).then(getDataFromResponse),
    queryOptions,
  );
};
