import { asyncActions, makeActionKeys } from 'utils/redux-utils';

export const CLEAR_DOCUMENT_CREATED = 'CLEAR_DOCUMENT_CREATED';
export const CLEAR_DOCUMENTS = 'CLEAR_DOCUMENTS';
export const CLEAR_SELECTED_EMPLOYEE = 'CLEAR_SELECTED_EMPLOYEE';
export const CLEAR_EMPLOYEE_CREATED = 'CLEAR_EMPLOYEE_CREATED';
export const ADD_SEAT = 'ADD_SEAT';
export const UPDATE_SEAT = 'UPDATE_SEAT';
export const UPDATE_COMPENSATION = 'UPDATE_COMPENSATION';
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';
export const UPDATE_EMPLOYEE_PATCH = 'UPDATE_EMPLOYEE_PATCH';

export const SET_FILE_INFO = 'SET_FILE_INFO';

export const asyncActionKeys = makeActionKeys([
  ...asyncActions('UPLOAD', 'DOCUMENT'),
  ...asyncActions('CREATE', 'DOCUMENT'),
  ...asyncActions('GET', 'DOCUMENTS'),
  ...asyncActions('DOWNLOAD', 'DOCUMENT'),
  ...asyncActions('VIEW', 'DOCUMENT'),
  ...asyncActions('GET', 'MANAGERS_EMPLOYEES'),
  ...asyncActions('GET', 'SEATS'),
  ...asyncActions('GET', 'EMPLOYEE'),
  ...asyncActions('UPDATE', 'EMPLOYEE'),
  ...asyncActions('CREATE', 'EMPLOYEE'),
  ...asyncActions('GET', 'PASSWORD_CHANGE_LINK'),
  ...asyncActions('DELETE', 'DOCUMENT'),
  ...asyncActions('GET', 'DATA_EXPORT'),
  ...asyncActions('UPDATE', 'EMPLOYEE_EMPLOYMENT_FAQ'),
  ...asyncActions('GET', 'TIMEOFFS'),
]);
