import {
  EntryDefinition,
  FormStage,
  Seat,
  StagedFormConfig,
  UserRoleType,
} from 'common/types';

import ConvertSeatToEmployeeSuccess from './../ConvertSeatToEmployeeSuccess/ConvertSeatToEmployeeSuccess';
import {
  getBenefitsSelectionStage,
  getBenefitsSelectionStageDeprecated,
} from './generateBenefitsSelectionStage';
import { generateContractDetailsStage } from './generateContractDetailsStage';
import { getStaticStages } from './staticFormStages';

export const COMPLETE_PROFILE_TITLE = 'Complete profile and build contract';
export const COMPLETE_PROFILE_COPY =
  'Any information you add here will appear in the employees profile and contract. \n Once added and payments processed, we will begin the onboarding process';

type StagesParams = {
  seat: Seat;
  entryDefinitions?: EntryDefinition[];
  companyName?: string;
  userRole?: UserRoleType;
  showBenefitsSelectionStep: boolean;
  enableClientBenefitSelection: boolean;
};

export const seatToEmployeeFormConfig = ({
  seat,
  entryDefinitions,
  companyName,
  userRole,
  showBenefitsSelectionStep,
  enableClientBenefitSelection,
}: StagesParams): StagedFormConfig => {
  const stages: FormStage[] = getStaticStages({
    seat,
    isPrivateAddressRequired: Boolean(entryDefinitions?.length),
    userRole,
  });

  if (showBenefitsSelectionStep) {
    stages.push(
      enableClientBenefitSelection
        ? getBenefitsSelectionStage()
        : getBenefitsSelectionStageDeprecated(seat.id),
    );
  }

  stages.push(
    generateContractDetailsStage(
      seat,
      userRole!,
      entryDefinitions,
      companyName,
    ),
  );

  const numberedStages = stages.map((stage, index) => ({
    ...stage,
    stage: index + 1,
  }));

  return {
    formTitle: COMPLETE_PROFILE_TITLE,
    formCopy: COMPLETE_PROFILE_COPY,
    stages: numberedStages,
    SuccessScreen: ConvertSeatToEmployeeSuccess,
  };
};
