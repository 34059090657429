import { getConfiguredLegalEntityIDs } from 'common/api/payrollTaxonomy.api';
import { useQuery } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

import { payrollKeys } from '../keys';

export const useConfiguredLegalEntityIDs = () =>
  useQuery(payrollKeys.configuredLEIDs(), () =>
    getConfiguredLegalEntityIDs().then(getDataFromResponse),
  );
