import { useMemo, useState } from 'react';

import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { FIVE, THIRTY_SECONDS } from 'common/constants';
import {
  TaxonomiesUpdateJob,
  TaxonomiesUpdateJobMessages,
  TaxonomiesUpdateJobStatus,
  User,
} from 'common/types';
import { CountryCode } from 'countries-and-timezones';
import { countries } from 'countries-list';
import { sortBy } from 'lodash';
import { useEmployersQuery } from 'utils/queries/employers/useEmployerQuery';
import { useConfiguredLegalEntityIDs } from 'utils/queries/payroll/useConfiguredLegalEntityIDs';
import { usePaginatedTaxonomiesUpdateJobsQuery } from 'utils/queries/payroll/usePaginatedTaxonomiesUpdateJobsQuery';
import { useTaxonomiesUpdateJobMutation } from 'utils/queries/payroll/useTaxonomiesUpdateJobMutation';
import { useUsersQuery } from 'utils/queries/users/useUsersQuery';

import {
  Box,
  Button,
  DateFormats,
  Dropdown,
  formatISODate,
  Inline,
  List,
  notifyError,
  notifySuccess,
  OptionType,
  SingleValue,
  Skeleton,
  Stack,
  Table,
  Tag,
  TagIntent,
  Typography,
} from '@omnipresentgroup/design-system';

enum ColumnHeaders {
  COUNTRY = 'Country',
  ENTITY = 'Entity',
  START_TIME = 'Start Time',
  STATUS = 'Status',
  ERRORS = 'Errors',
  USER = 'User',
}

const STATUS_TAG_MAP: {
  [Property in TaxonomiesUpdateJobStatus]: { intent: TagIntent; label: string };
} = {
  CREATED: { intent: 'info', label: 'Submitted' },
  STARTED: { intent: 'warning', label: 'Started' },
  FINISHED: { intent: 'success', label: 'Done' },
  ERROR: { intent: 'error', label: 'Error' },
};

const statusOptions = [
  TaxonomiesUpdateJobStatus.CREATED,
  TaxonomiesUpdateJobStatus.STARTED,
  TaxonomiesUpdateJobStatus.FINISHED,
  TaxonomiesUpdateJobStatus.ERROR,
].map((status) => ({
  label: STATUS_TAG_MAP[status].label,
  value: status,
}));

const columnHelper = createColumnHelper<TaxonomiesUpdateJob>();

const LOADING_DATA = Array.from({ length: FIVE }, (_value, index) => index);

const LoadingColumns: ColumnDef<any, any>[] = [
  columnHelper.display({
    id: 'country',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.COUNTRY,
    size: 100,
  }),
  columnHelper.display({
    id: 'entity',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.ENTITY,
    size: 150,
  }),
  columnHelper.display({
    id: 'start-time',
    cell: () => <Skeleton height={16} />,
    maxSize: 20,
    header: () => ColumnHeaders.START_TIME,
    size: 16,
  }),
  columnHelper.display({
    id: 'status',
    cell: () => <Skeleton height={16} />,
    maxSize: 20,
    header: () => ColumnHeaders.STATUS,
    size: 16,
  }),
  columnHelper.display({
    id: 'errors',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.ERRORS,
    size: 150,
  }),
  columnHelper.display({
    id: 'user',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.USER,
    maxSize: 100,
  }),
];

const PAGE_SIZE_OPTIONS = [20, 50];

export const TaxonomiesUpdateTab = () => {
  const { data: employersList = [], isLoading: isLoadingEmployers } =
    useEmployersQuery();

  const { data: configuredLEIDs = [], isLoading: isLoadingConfLEs } =
    useConfiguredLegalEntityIDs();

  const { entitiesOptions, countryOptions, leById, leOptionsByCountryCode } =
    useMemo(() => {
      const configuredLEIDsSet = new Set(configuredLEIDs);
      const entities: { value: string; label: string }[] = [];
      const { leByIdMap, leByCountryCode, countryCodes } = employersList.reduce(
        (acc, entity) => {
          if (!configuredLEIDsSet.has(entity.id)) {
            return acc;
          }

          acc.leByIdMap.set(entity.id, {
            name: entity.name,
            countryName:
              countries?.[entity.countryCode.toUpperCase() as CountryCode]
                ?.name || entity.countryCode,
          });
          acc.countryCodes.add(entity.countryCode);

          const option = { value: entity.id, label: entity.name };
          entities.push(option);
          if (acc.leByCountryCode.has(entity.countryCode)) {
            acc.leByCountryCode.get(entity.countryCode)?.push(option);
          } else {
            acc.leByCountryCode.set(entity.countryCode, [option]);
          }
          return acc;
        },
        {
          leByIdMap: new Map<string, { name: string; countryName: string }>(),
          leByCountryCode: new Map<
            string,
            { value: string; label: string }[]
          >(),
          countryCodes: new Set<string>(),
        },
      );

      const options = [...countryCodes].map((code) => {
        const country = countries?.[code.toUpperCase() as CountryCode];
        return {
          value: code,
          label: country ? `${country.emoji} ${country.name}` : code,
        };
      });

      return {
        entitiesOptions: sortBy(entities, 'label'),
        countryOptions: sortBy(options, 'label'),
        leById: leByIdMap,
        leOptionsByCountryCode: leByCountryCode,
      };
    }, [employersList, configuredLEIDs]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: PAGE_SIZE_OPTIONS[0],
  });

  const [countryFilter, setCountryFilter] =
    useState<SingleValue<OptionType>>(null);
  const [entityFilter, setEntityFilter] =
    useState<SingleValue<OptionType>>(null);
  const [statusFilter, setStatusFilter] =
    useState<SingleValue<OptionType>>(null);

  const {
    data,
    isLoading: isLoadingJobs,
    remove,
    refetch,
  } = usePaginatedTaxonomiesUpdateJobsQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      ...(entityFilter ? { legalEntityId: entityFilter.value.toString() } : {}),
      ...(countryFilter ? { countryCode: countryFilter.value.toString() } : {}),
      ...(statusFilter ? { status: statusFilter.value.toString() } : {}),
    },
    { refetchInterval: THIRTY_SECONDS },
  );

  const { items: taxonomiesUpdateJobs = [], totalCount = 0 } = data || {};
  const pageCount = Math.ceil(totalCount / pagination.pageSize);
  const { isLoading: isCreatingNewJob, mutate: createTaxonomiesUpdateJob } =
    useTaxonomiesUpdateJobMutation();

  const queriesResult = useUsersQuery(
    taxonomiesUpdateJobs.map(({ createdBy }) => createdBy),
  );
  const isLoadingUsers = queriesResult.some((result) => result.isLoading);
  const userData = queriesResult.reduce((acc, { isSuccess, data: user }) => {
    if (isSuccess && user) {
      acc.set(user.authId, user);
    }
    return acc;
  }, new Map<string, User>());

  const [selectedCountry, setSelectedCountry] =
    useState<SingleValue<OptionType> | null>(null);

  const [selectedLE, setSelectedLE] = useState<SingleValue<OptionType> | null>(
    null,
  );

  const columns: ColumnDef<TaxonomiesUpdateJob, any>[] = [
    columnHelper.accessor('countryCode', {
      cell: (info) => {
        const countryCode = info.getValue();
        return (
          countries?.[countryCode.toUpperCase() as CountryCode]?.name ||
          countryCode
        );
      },
      maxSize: 16,
      header: ColumnHeaders.COUNTRY,
    }),
    columnHelper.accessor('legalEntityId', {
      id: 'entity',
      cell: (info) => leById.get(info.getValue())?.name || info.getValue(),
      header: ColumnHeaders.ENTITY,
    }),
    columnHelper.accessor('createdAt', {
      cell: (info) => formatISODate(info.getValue(), DateFormats.DateTime),
      minSize: 200,
      header: ColumnHeaders.START_TIME,
    }),
    columnHelper.accessor('status', {
      cell: (info) => {
        const value = info.getValue() as TaxonomiesUpdateJobStatus;
        return (
          <Tag
            id="taxonomies-update-job-tag"
            intent={STATUS_TAG_MAP[value].intent}
          >
            {STATUS_TAG_MAP[value].label}
          </Tag>
        );
      },
      maxSize: 12,
      header: ColumnHeaders.STATUS,
    }),
    columnHelper.accessor('messages', {
      cell: (info) => {
        const messages = info.getValue() as TaxonomiesUpdateJobMessages;
        const errors = messages?.errors || [];

        return (
          <List
            direction="column"
            gap="0"
            items={errors}
            keyExtractor={(item) => item.message}
            render={(item) => (
              <Typography as="span" size="14">
                {item.message}
              </Typography>
            )}
            showDisc
          ></List>
        );
      },
      header: ColumnHeaders.ERRORS,
    }),
    columnHelper.accessor('createdBy', {
      cell: (info) => {
        const userId = info.getValue();
        return (
          userData?.get(userId)?.name ||
          userData?.get(userId)?.displayName ||
          userId
        );
      },
      header: ColumnHeaders.USER,
    }),
  ];

  const isLoading =
    isLoadingEmployers || isLoadingJobs || isLoadingUsers || isLoadingConfLEs;
  const loadingTable = useReactTable({
    data: LOADING_DATA,
    columns: LoadingColumns,
    getCoreRowModel: getCoreRowModel(),
  });
  const table = useReactTable({
    data: taxonomiesUpdateJobs,
    columns: isLoading ? LoadingColumns : columns,
    getCoreRowModel: getCoreRowModel(),
    state: { pagination },
    manualPagination: true,
    onPaginationChange: setPagination,
  });

  return (
    <Stack gap="24">
      <Dropdown
        id="country-dropdown"
        label="Country"
        isMulti={false}
        onChange={(e) => {
          setSelectedCountry(e);
          setSelectedLE(null);
        }}
        value={selectedCountry}
        options={countryOptions}
        isLoading={isLoading}
        isDisabled={isLoading}
        isClearable
        isSearchable
        placeholder="Select a Country"
      />
      <Dropdown
        id="le-dropdown"
        label="Entity"
        onChange={(e) => {
          setSelectedLE(e);
        }}
        value={selectedLE}
        options={
          selectedCountry
            ? leOptionsByCountryCode.get(selectedCountry.value.toString())
            : []
        }
        isDisabled={isLoading || !selectedCountry}
        isMulti={false}
        isLoading={isLoading}
        isClearable
        isSearchable
        placeholder="Select an Entity"
      />
      <Box>
        <Button
          disabled={!selectedCountry || !selectedLE}
          loading={isCreatingNewJob}
          onClick={() => {
            if (!selectedCountry || !selectedLE) {
              return;
            }

            createTaxonomiesUpdateJob(
              {
                countryCode: selectedCountry.value.toString(),
                legalEntityId: selectedLE.value.toString(),
              },
              {
                onSuccess: () => {
                  notifySuccess({
                    title: 'Update request created successfully',
                  });
                  refetch();
                },
                onError: () => {
                  notifyError({
                    title: 'Error creating update request',
                  });
                },
                onSettled: () => {
                  setSelectedCountry(null);
                  setSelectedLE(null);
                },
              },
            );
          }}
        >
          Update Entity Taxonomies
        </Button>
      </Box>
      <Stack radius="m" bg="primary" border="subtle" pt="24">
        <Box px="24">
          <Typography as="span" size="20" weight="medium">
            Update Requests
          </Typography>
        </Box>
        <Inline pt="24" px="24" stretch="all" stackAt={700}>
          <Dropdown
            id="country-filter"
            label="Filter by Country"
            placeholder="Select a Country"
            options={countryOptions}
            isLoading={isLoading}
            isDisabled={isLoading}
            isSearchable
            isClearable
            isMulti={false}
            value={countryFilter}
            onChange={(e) => {
              setCountryFilter(e);
            }}
          ></Dropdown>
          <Dropdown
            id="le-filter"
            label="Filter by Entity"
            placeholder="Select an Entity"
            options={entitiesOptions}
            isLoading={isLoading}
            isDisabled={isLoading}
            isSearchable
            isClearable
            isMulti={false}
            value={entityFilter}
            onChange={(e) => {
              setEntityFilter(e);
            }}
          ></Dropdown>
          <Box minW={250} maxW={260}>
            <Dropdown
              id="statut-filter"
              label="Filter by Status"
              placeholder="Select a Status"
              options={statusOptions}
              isLoading={isLoading}
              isDisabled={isLoading}
              isSearchable
              isClearable
              isMulti={false}
              value={statusFilter}
              onChange={(e) => {
                setStatusFilter(e);
              }}
            ></Dropdown>
          </Box>
          <Box maxW={40}>
            <Button
              variant="tertiary"
              icon="Refresh"
              onClick={() => {
                remove();
                refetch();
              }}
            ></Button>
          </Box>
        </Inline>
        <Table
          table={isLoading ? loadingTable : table}
          wrapperProps={{ style: { border: 'none' } }}
          usePagination
          paginationProps={{
            pageCount,
            canNextPage: pagination.pageIndex < pageCount - 1,
            canPreviousPage: pagination.pageIndex >= 0,
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
          }}
        ></Table>
      </Stack>
    </Stack>
  );
};
