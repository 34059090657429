import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { PageHeader } from 'common/components';
import { Tabs } from 'common/components/organisms/Tabs/Tabs';
import {
  CutoffReportsTab,
  FXRatesTab,
  PayrollInstancesTab,
  TaxonomiesUpdateTab,
} from 'omniplatform/admin/pages/PayrollPage/Tabs';

import 'omniplatform/admin/pages/PayrollPage/Tabs/CutoffReportsTab';

import { StyledPayrollPage } from './PayrollPage.styles';
import { ProviderIdTab } from './Tabs/ProviderIdTab/ProviderIdTab';

export const PayrollPage = () => {
  const location = useLocation();
  const [currentHash, setCurrentHash] = useState(location.hash || null);

  useEffect(() => {
    setCurrentHash(location.hash);
  }, [location]);

  const tabs = [
    {
      id: 1,
      title: 'Workbooks',
      href: '#payroll-instances',
      Content: PayrollInstancesTab,
    },
    {
      id: 2,
      title: 'FX Rates',
      href: '#fx-rates',
      Content: FXRatesTab,
    },
    {
      id: 3,
      title: 'Provider ID',
      href: '#provider-id',
      Content: ProviderIdTab,
    },
    {
      id: 4,
      title: 'Cutoff Reports',
      href: '#cutoff-reports',
      Content: CutoffReportsTab,
    },
    {
      id: 5,
      title: 'Taxonomies Update',
      href: '#taxonomies-update',
      Content: TaxonomiesUpdateTab,
    },
  ];

  return (
    <PageContent>
      <PageHeader title="Payroll" testId="payroll-header" />
      <StyledPayrollPage data-testid="payroll-page" className="bigStack">
        <Tabs
          tabsToDisplay={tabs}
          currentTab={
            currentHash
              ? tabs.filter((tab) => tab.href === currentHash)[0]
              : tabs[0]
          }
        />
      </StyledPayrollPage>
    </PageContent>
  );
};
